import { render, staticRenderFns } from "./OfferComments.vue?vue&type=template&id=a666d910&scoped=true&"
import script from "./OfferComments.vue?vue&type=script&lang=js&"
export * from "./OfferComments.vue?vue&type=script&lang=js&"
import style0 from "./OfferComments.vue?vue&type=style&index=0&id=a666d910&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a666d910",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/home/gitlab-runner/builds/D6nERZR5/0/sunmedia/asterix/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('a666d910')) {
      api.createRecord('a666d910', component.options)
    } else {
      api.reload('a666d910', component.options)
    }
    module.hot.accept("./OfferComments.vue?vue&type=template&id=a666d910&scoped=true&", function () {
      api.rerender('a666d910', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/organisms/modules/ecommerce/offer/OfferComments.vue"
export default component.exports