<template>
  <div class="w-full text-sm">
    <asterix-comments
      :comment-list="commentList"
      :tags="tags"
      :loading="isLoading"
      @create-or-update="onCreateOrUpdateComment"
      @delete="deleteComment"
    >
      <template #title>Comments</template>
    </asterix-comments>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { COMMENT_TAGS } from '@/model/shared/Tags';
import { CREATE_TOAST } from '@/store/modules/toast/keys';
import { Toast } from '@/model/shared/Toast';

import AsterixComments from '@/components/organisms/shared/asterixComments/AsterixComments';
import { createOfferComment, updateOfferComment, deleteOfferComment } from '@/services/modules/ecommerce/offer';
import Offer from '@/entities/ecommerce/Offer';

export default {
  name: 'OfferComments',
  components: {
    AsterixComments,
  },
  props: {
    offer: {
      type: Offer,
      default: null,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    tags: COMMENT_TAGS,
  }),
  computed: {
    commentList() {
      return this.offer?.comments || [];
    },
  },
  methods: {
    ...mapActions({ createToast: CREATE_TOAST }),
    async onCreateOrUpdateComment(comment) {
      const updateOrCreateText = comment?.id ? 'updated' : 'created';
      try {
        if (comment.id) {
          await updateOfferComment(this.offer.id, comment);
        } else {
          await createOfferComment(this.offer.id, comment);
        }
        const toast = Toast.success(`Comment ${updateOrCreateText}`, `Comment was ${updateOrCreateText} successfully`);
        this.createToast(toast);

        this.$emit('update', this.offer);
      } catch (e) {
        this.createToast(Toast.error(`Comment not ${updateOrCreateText}`, e.message));
      }
    },
    async deleteComment(comment) {
      try {
        await deleteOfferComment(this.offer.id, comment.id);
        const toast = Toast.success('Comment deleted', 'Comment was deleted successfully');
        this.createToast(toast);

        this.$emit('update', this.offer);
      } catch (e) {
        this.createToast(Toast.error('Comment not deleted', e.message));
      }
    },
  },
};
</script>
<style scoped>
.fit-content {
  width: fit-content;
}
</style>
